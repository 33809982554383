import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GrainIcon from "@material-ui/icons/Grain";
import { TFunction } from "i18next";
import { IaqBox } from "./iaq-box.component";
import { AirQualityDetail } from "./air-quality-detail.component";

interface AirQualityItemProps {
  t: TFunction;
  detail?: boolean;
  pm10: number;
  pm25: number;
  iaq10: number;
  iaq25: number;
}

export const AirQualityItem: React.FC<AirQualityItemProps> = ({
  t,
  detail,
  iaq10,
  iaq25,
  pm10,
  pm25
}) => (
  <>
    <ListItem>
      <ListItemIcon>
        <GrainIcon />
      </ListItemIcon>
      <ListItemText>
        <strong>{t("airquality")}:</strong>{" "}
        <IaqBox value={Math.max(iaq10, iaq25)} />
        {detail && (
          <AirQualityDetail
            iaq10={iaq10}
            iaq25={iaq25}
            pm10={pm10}
            pm25={pm25}
            t={t}
          />
        )}
      </ListItemText>
    </ListItem>
  </>
);
