import React, { lazy, Suspense } from "react";
import { useLocation } from "wouter";
import HelpIcon from "@material-ui/icons/Help";
import HistoryIcon from "@material-ui/icons/History";
import MapIcon from "@material-ui/icons/Map";
import RoomIcon from "@material-ui/icons/Room";
import LanguageIcon from "@material-ui/icons/Translate";
import { useConfig } from "../hooks";
import { LoadingBackdrop } from "./common";
import { useMainComponentStyles } from "./main.styles";
import { Action } from "./navigation/navigation.component";
import { CO2IconComponent } from "./co2-icon";

const AppBarComponent = lazy(() => import("./app-bar"));
const NavigationBar = lazy(() => import("./navigation"));
const RouterComponent = lazy(() => import("./router.component"));
const TranslationDialogComponent = lazy(() => import("./translation-dialog"));

export const MainComponent: React.FC = () => {
  const location = useLocation();
  const {
    config: {
      sensorTypes: { indoor, outdoor },
      showHelp,
    },
  } = useConfig();
  const [showTranslations, setShowTranslations] = React.useState<boolean>(
    false
  );
  const [action, setAction] = React.useState<Action>({
    value: "map",
    label: "map",
    icon: <MapIcon />,
  });
  const classes = useMainComponentStyles();
  const actions: Action[] = [
    { value: "/map", label: "map", icon: <MapIcon /> },
    { value: "/node", label: "nodes", icon: <RoomIcon /> },
    {
      value: "/indoor-location",
      label: "indoor",
      icon: <CO2IconComponent />,
    },
    { value: "/history", label: "history", icon: <HistoryIcon /> },
    { value: "/help", label: "help", icon: <HelpIcon /> },
    { value: "language", label: "language", icon: <LanguageIcon /> },
  ];

  const handleNavigation = (action: Action) => {
    const { value } = action;
    value !== "language" && setAction(action);
    value === "language" ? setShowTranslations(true) : location[1](value);
  };

  const handleCloseTranslations = () => {
    setShowTranslations(false);
  };

  return (
    <div className={classes.root}>
      {showTranslations && (
        <Suspense fallback={<LoadingBackdrop />}>
          <TranslationDialogComponent
            open={showTranslations}
            handleClose={handleCloseTranslations}
          />
        </Suspense>
      )}
      <div className={classes.appBar}>
        <Suspense fallback={<LoadingBackdrop />}>
          <AppBarComponent />
        </Suspense>
      </div>
      <div className={classes.main}>
        <Suspense fallback={<LoadingBackdrop />}>
          <RouterComponent />
        </Suspense>
      </div>
      <div className={classes.navigation}>
        <Suspense fallback={<LoadingBackdrop />}>
          <NavigationBar
            actions={actions.filter(
              (el) =>
                (el.label === "indoor" && indoor) ||
                (el.label === "nodes" && outdoor) ||
                (el.label === "help" && showHelp) ||
                (el.label !== "indoor" &&
                  el.label !== "nodes" &&
                  el.label !== "help")
            )}
            onNavigationChange={handleNavigation}
            value={action}
          />
        </Suspense>
      </div>
    </div>
  );
};
