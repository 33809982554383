import { makeStyles } from "@material-ui/core/styles";

export const useIaqBoxStyles = makeStyles(theme => ({
  root: {
    padding: ".1em",
    fontSize: ".9em",
    border: ".1em solid #333",
    fontWeight: "bold",
    cursor: "pointer",
    verticalAlign: "text-bottom"
  },
  typography: {
    padding: theme.spacing(2)
  },
  good: {
    background: "#afa",
    color: "#222"
  },
  moderate: {
    background: "#0d0",
    color: "#222"
  },
  unhealthySensitive: {
    background: "#eba834",
    color: "#fff"
  },
  unhealthy: {
    background: "#eb3434",
    color: "#fff"
  },
  veryUnhealthy: {
    background: "#470073",
    color: "#fff"
  },
  hazardous: {
    background: "#573800",
    color: "#fff"
  }
}));

export const useIaqMarkerStyles = makeStyles({
  good: {
    color: "#3f3"
  },
  moderate: {
    color: "#0b0"
  },
  unhealthySensitive: {
    color: "#eba834"
  },
  unhealthy: {
    color: "#f79d00"
  },
  veryUnhealthy: {
    color: "#470073"
  },
  hazardous: {
    color: "#573800"
  }
});
