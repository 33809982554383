import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Co2Box } from "./co2-box.component";
import { Link } from "wouter";

interface RoomItemProps {
  name: string;
  co2: number;
  link: string;
}

export const RoomItem: React.FC<RoomItemProps> = ({ co2, name, link }) => (
  <ListItem>
    <ListItemText>
      <Link
        href={link}
        style={{
          cursor: "pointer",
          fontWeight: "bold",
          textDecoration: "none",
          color: "inherit",
        }}
      >
        {name}
      </Link>{" "}
      CO<sub>2</sub>: <Co2Box value={co2} />
    </ListItemText>
  </ListItem>
);
