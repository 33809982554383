import * as React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { getCo2TypeByValue } from "../../models";
import { useTranslation } from "react-i18next";
import { useCo2BoxStyles } from "./co2-box.styles";

interface Co2BoxProps {
  value: number;
}

export const Co2Box: React.FC<Co2BoxProps> = ({ value }) => {
  const classes = useCo2BoxStyles();
  const { t } = useTranslation();
  const co2Type = getCo2TypeByValue(value);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "co2-popover" : undefined;

  return (
    <>
      <button
        className={`${classes.root} ${classes[co2Type]}`}
        onClick={handleClick}
      >
        {value}ppm
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={`${classes.typography} ${classes[co2Type]}`}>
          {t(co2Type)}
        </Typography>
      </Popover>
    </>
  );
};
