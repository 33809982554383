import { createContext } from "react";

export interface ConfigContextModel {
  title: string;
  url: string;
  map: {
    mapStyle: string;
    latitude: number;
    longitude: number;
    zoom: number;
  };
  showHelp: boolean;
  showLanguageChange?: boolean;
  sensorTypes: {
    outdoor: boolean;
    indoor: boolean;
  };
  sensors: ("pax" | "ambient" | "airQuality")[];
}

export const initConfig: ConfigContextModel = {
  title: "",
  url: "",
  map: {
    mapStyle: "",
    latitude: 0,
    longitude: 0,
    zoom: 1,
  },
  showHelp: false,
  sensorTypes: {
    outdoor: false,
    indoor: false,
  },
  showLanguageChange: true,
  sensors: [],
};

export const ConfigContext = createContext<ConfigContextModel>(initConfig);
