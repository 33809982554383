export type PaxType = "low" | "medium" | "high" | "veryHigh";

export interface PaxLutItem {
  type: PaxType;
  min: number;
  max: number;
}

const paxLut: PaxLutItem[] = [
  {
    type: "low",
    min: 0,
    max: 20
  },
  {
    type: "medium",
    min: 21,
    max: 40
  },
  {
    type: "high",
    min: 41,
    max: 60
  },
  {
    type: "veryHigh",
    min: 61,
    max: 999
  }
];

export const getPaxTypeByPaxcount = (pax: number): PaxType =>
  paxLut.find(value => value.min <= pax && value.max >= pax)?.type || "medium";
