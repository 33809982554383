import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useNotFoundStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:before": {
      content: "' '",
      position: "fixed",
      width: "200%",
      height: "200%",
      top: "-50%",
      left: "-50%",
      zIndex: 0,
      background: "url(/assets/logo.png) center center no-repeat",
      transform: "rotate(30deg) scale(3.5)",
      filter: "grayscale(.9) blur(5px) opacity(0.4) contrast(112%)"
    }
  },
  content: {
    zIndex: 10
  }
});

export const NotFoundComponent: React.FC = () => {
  const classes = useNotFoundStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1" color="secondary">
          404
        </Typography>
        <Typography component="p" variant="subtitle1" color="primary">
          {t("notFound")}
        </Typography>
      </div>
    </div>
  );
};
