import * as React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { getIaqTypeByValue } from "../../models";
import { useTranslation } from "react-i18next";
import { useIaqBoxStyles } from "./iaq-box.styles";

interface IaqBoxProps {
  value: number;
}

export const IaqBox: React.FC<IaqBoxProps> = ({ value }) => {
  const classes = useIaqBoxStyles();
  const { t } = useTranslation();
  const iaqType = getIaqTypeByValue(value);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "iaq-popover" : undefined;

  return (
    <>
      <button
        className={`${classes.root} ${classes[iaqType]}`}
        onClick={handleClick}
      >
        IAQ {value}
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Typography className={`${classes.typography} ${classes[iaqType]}`}>
          <strong>{t("airquality")}:</strong> {t(iaqType)}
        </Typography>
      </Popover>
    </>
  );
};
