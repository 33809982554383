import { useEffect, useState } from "react";
import { useDebounce } from "./debounce.hook";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const debouncedWindowSize = useDebounce<{ width: number; height: number }>(
    windowSize,
    100
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {}, [windowSize]);

  return debouncedWindowSize;
};
