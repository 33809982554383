import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { LineSeriesPoint } from "react-vis";
import { makeStyles } from "@material-ui/core/styles";

interface HintComponentProps {
  value: LineSeriesPoint;
  unit: string;
  valueName: string;
}

const useHintStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(2),
  },
}));

export const HintComponent: React.FC<HintComponentProps> = ({
  unit,
  value,
  valueName,
}) => {
  const classes = useHintStyles();
  return (
    <Paper elevation={5} className={classes.paperRoot}>
      <Typography variant="button" align="center" noWrap>
        {new Date(value.x).toLocaleString()}
      </Typography>
      <Typography align="center" noWrap>
        <strong>{valueName}:</strong> {`${value.y} ${unit}`}
      </Typography>
    </Paper>
  );
};
