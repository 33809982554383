import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useLoadingStyles = makeStyles({
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99999
  }
});
export const LoadingComponent: React.FC = () => {
  const classes = useLoadingStyles();
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};
