import { useState, useEffect } from "react";
import { IndoorLocation } from "../models";
import { filteredIndoorMeasurementsApi } from "../api-routes";

interface IndoorFilterOptions {
  locationId: number;
  roomId?: string;
  startDate?: Date;
  endDate?: Date;
}

export const useFilterIndoorMeasurements = (
  filterState: IndoorFilterOptions
) => {
  const [indoorMeasurements, setIndoorMeasurements] = useState<
    IndoorLocation[]
  >([]);
  const [indoorLoading, setIndoorLoading] = useState<boolean>(true);
  const { locationId, endDate, startDate, roomId } = filterState;
  useEffect(() => {
    if (locationId === 0) {
      setIndoorLoading(false);
      setIndoorMeasurements([]);
    } else {
      setIndoorLoading(true);
      fetch(
        `${filteredIndoorMeasurementsApi}?locationId=${locationId}${
          startDate ? `&startDate=${startDate.toISOString()}` : ""
        }${endDate ? `&endDate=${endDate.toISOString()}` : ""}${
          roomId ? `&roomId=${roomId}` : ""
        }`
      )
        .then((response) => response.json() as Promise<IndoorLocation[]>)
        .then(setIndoorMeasurements)
        .catch((error) => {
          alert("Error requesting filtered indoor measurements");
          console.error(error);
        })
        .finally(() => {
          setIndoorLoading(false);
        });
    }
  }, [locationId, startDate, endDate, roomId]);

  return { indoorMeasurements, indoorLoading };
};
