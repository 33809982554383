import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GrainIcon from "@material-ui/icons/Grain";
import { TFunction } from "i18next";
import { Co2Box } from "./co2-box.component";

interface RCo2SingleItemProps {
  t: TFunction;
  co2: number;
}

export const Co2SingleItem: React.FC<RCo2SingleItemProps> = ({ t, co2 }) => (
  <ListItem>
    <ListItemIcon>
      <GrainIcon />
    </ListItemIcon>
    <ListItemText>
      <strong>
        CO<sub>2</sub>:
      </strong>{" "}
      <Co2Box value={co2} />
    </ListItemText>
  </ListItem>
);
