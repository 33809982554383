import { makeStyles } from "@material-ui/core/styles";

export const useCo2BoxStyles = makeStyles((theme) => ({
  root: {
    padding: ".1em",
    fontSize: ".9em",
    border: ".1em solid #333",
    fontWeight: "bold",
    cursor: "pointer",
    verticalAlign: "text-bottom",
  },
  typography: {
    padding: theme.spacing(2),
  },
  good: {
    background: "#afa",
    color: "#222",
  },
  moderate: {
    background: "#ebeb34",
    color: "#222",
  },
  bad: {
    background: "#eb3434",
    color: "#222",
  },
}));

export const useCo2MarkerStyles = makeStyles({
  good: {
    color: "#3f3",
  },
  moderate: {
    color: "#ebeb34",
  },
  bad: {
    color: "#f79d00",
  },
});
