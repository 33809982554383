import { useState, useEffect } from "react";
import { Theme } from "@material-ui/core/styles";
import { ConfigContextModel, initConfig } from "../contexts";

export const useConfig = () => {
  const [theme, setTheme] = useState<Theme>({} as Theme);
  const [config, setConfig] = useState<ConfigContextModel>(initConfig);

  useEffect(() => {
    fetch("/assets/theme.json")
      .then(response => response.json())
      .then(setTheme);

    fetch("/assets/config.json")
      .then(response => response.json())
      .then(setConfig);
  }, []);

  return { theme, config };
};
