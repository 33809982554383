import React from "react";
import GoogleFontLoader from "react-google-font-loader";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, Theme, ThemeProvider } from "@material-ui/core/styles";
import { MainComponent } from "./components";
import { ConfigContext } from "./contexts";
import { useConfig } from "./hooks";
import "./i18n";
import { LoadingBackdrop } from "./components/common";

const App = () => {
  const { theme, config } = useConfig();
  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: "Roboto",
            weights: [300, 400, 500, 700],
          },
        ]}
        display="swap"
      />
      <CssBaseline />
      {theme === ({} as Theme) || config.title === "" ? (
        <LoadingBackdrop />
      ) : (
        <ThemeProvider theme={createMuiTheme(theme)}>
          <ConfigContext.Provider value={config}>
            <MainComponent />
          </ConfigContext.Provider>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
