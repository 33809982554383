export const measurementsApi =
  process.env.NODE_ENV !== "production"
    ? //? "http://localhost:3001/api/measurements"
      "/api/outdoor-sensors.json"
    : "/apiv2/outdoor-sensors";

export const filteredMeasurementsApi =
  process.env.NODE_ENV !== "production"
    ? //? "http://localhost:3001/api/measurements"
      "/api/outdoor-sensors-filtered.json"
    : "/apiv2/outdoor-sensors/filter";

export const indoorMeasurementsApi =
  process.env.NODE_ENV !== "production"
    ? //? "http://localhost:3001/api/indoor-measurements"
      "/api/indoor-sensors.json"
    : "/apiv2/indoor-sensors";

export const filteredIndoorMeasurementsApi =
  process.env.NODE_ENV !== "production"
    ? //? "http://localhost:3001/api/indoor-measurements"
      "/api/indoor-sensors-filtered.json"
    : "/apiv2/indoor-sensors/filter";

export const subscriptionApiSubscribe =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3001/sensors/subscribe/"
    : //"/api/add-subscription.json"
      "/apiv2/subscription/sensors/subscribe/";

export const subscriptionApiConfirmSubscription =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3001/email/"
    : //  "/api/add-subscription.json"
      "/apiv2/subscription/email/";

export const subscriptionApiUnsubscribe =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3001/sensors/subscription/"
    : //  "/api/add-subscription.json"
      "/apiv2/subscription/sensors/subscription/";
