import * as React from "react";
import { TFunction } from "i18next";
import { IaqBox } from "./iaq-box.component";
import { useAirQualityDetailStyles } from "./air-quality-detail.styles";

interface AirQualityDetailProps {
  t: TFunction;
  pm10: number;
  pm25: number;
  iaq10: number;
  iaq25: number;
}

export const AirQualityDetail: React.FC<AirQualityDetailProps> = ({
  t,
  pm10,
  iaq10,
  iaq25,
  pm25,
}) => {
  const classes = useAirQualityDetailStyles();
  return (
    <ul className={classes.pmListItem}>
      <li>
        <strong>PM 2.5:</strong> {pm25}
        &micro;g/m<sup>3</sup> <IaqBox value={iaq25} />
      </li>
      <li>
        <strong>PM 10:</strong> {pm10}
        &micro;g/m<sup>3</sup> <IaqBox value={iaq10} />
      </li>
    </ul>
  );
};
