import { useState, useEffect } from "react";
import { OutdoorLocation } from "../models";
import { measurementsApi } from "../api-routes";

export const useMeasurements = () => {
  const [measurements, setMeasurements] = useState<OutdoorLocation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(measurementsApi)
      .then((response) => response.json() as Promise<OutdoorLocation[]>)
      .then(setMeasurements)
      .catch((error) => {
        alert("Error requesting measurements");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { measurements, loading };
};
