import * as React from "react";
import { TFunction } from "i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HumidityIcon from "@material-ui/icons/Opacity";
import TemperatureIcon from "@material-ui/icons/AcUnit";
import PressureIcon from "@material-ui/icons/FilterDrama";

interface WeatherItemProps {
  t: TFunction;
  temperature: number;
  humidity: number;
  pressure?: number;
}

export const WeatherItem: React.FC<WeatherItemProps> = ({
  t,
  humidity,
  pressure,
  temperature,
}) => (
  <>
    {temperature !== undefined && (
      <ListItem>
        <ListItemIcon>
          <TemperatureIcon />
        </ListItemIcon>
        <ListItemText>
          <strong>{t("temperature")}:</strong> {temperature}&deg;C
        </ListItemText>
      </ListItem>
    )}
    {humidity !== undefined && (
      <ListItem>
        <ListItemIcon>
          <HumidityIcon />
        </ListItemIcon>
        <ListItemText>
          <strong>{t("humidity")}:</strong> {humidity}%
        </ListItemText>
      </ListItem>
    )}
    {pressure !== undefined && (
      <ListItem>
        <ListItemIcon>
          <PressureIcon />
        </ListItemIcon>
        <ListItemText>
          <strong>{t("pressure")}:</strong> {pressure}mBar
        </ListItemText>
      </ListItem>
    )}
  </>
);
