export interface OutdoorMeasurement {
  timestamp: Date;
  pax: number;
  humidity: number;
  temperature: number;
  pressure: number;
  pm10: number;
  iaq10: number;
  pm25: number;
  iaq25: number;
}

export interface OutdoorLocation {
  latitude: number;
  longitude: number;
  locationId: number;
  locationName: string;
  measurements: OutdoorMeasurement[];
}

export enum MeasurementUnit {
  "timestamp" = "Time",
  "temperature" = "°C",
  "humidity" = "%",
  "pressure" = "hPa",
  "barometer" = "hPa",
  "pm25" = "µg/m³",
  "pm10" = "µg/m³",
  "pax" = "%",
  "iaq25" = "",
  "iaq10" = "",
  "co2" = "ppm",
}

export const getMeasurementRange = (
  measurement: keyof OutdoorMeasurement,
  values: number[]
): number[] | undefined => {
  if (!!!values) {
    return [0, 100];
  }
  switch (measurement) {
    case "temperature":
      return [Math.min(...values) / 1.5, Math.max(...values) * 1.5];
    case "humidity":
      return [0, 100];
    case "pressure":
      return [Math.min(...values) / 1.01, Math.max(...values) * 1.01];
    case "pax":
      return [0, Math.max(Math.max(...values), 100)];
    case "iaq10":
      return [0, 500];
    case "iaq25":
      return [0, 500];
    default:
      return [0, 100];
  }
};
