import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { OutdoorMeasurement } from "../../models";
import { SingleGraphComponent } from "../node/single-graph.component";
import { ConfigContext } from "../../contexts";

interface GraphsCardProps {
  measurements: OutdoorMeasurement[];
  locationName: string;
}

const graphsToRender: (keyof OutdoorMeasurement)[] = [
  "temperature",
  "humidity",
  "pressure",
  "pm25",
  "pm10",
  "pax",
];

const measurementsMapper =
  (sensors: ("pax" | "ambient" | "airQuality")[]) =>
  (measurement: OutdoorMeasurement): Partial<OutdoorMeasurement> => ({
    ...measurement,
    temperature: sensors.includes("ambient")
      ? measurement.temperature
      : undefined,
    humidity: sensors.includes("ambient") ? measurement.humidity : undefined,
    pressure: sensors.includes("ambient") ? measurement.pressure : undefined,
    pax: sensors.includes("pax") ? measurement.pax : undefined,
    pm10: sensors.includes("airQuality") ? measurement.pm10 : undefined,
    pm25: sensors.includes("airQuality") ? measurement.pm25 : undefined,
    iaq10: sensors.includes("airQuality") ? measurement.iaq10 : undefined,
    iaq25: sensors.includes("airQuality") ? measurement.iaq25 : undefined,
  });

const shouldRenderMeasure =
  (sensors: ("pax" | "ambient" | "airQuality")[]) =>
  (measurement: keyof OutdoorMeasurement): boolean =>
    (sensors.includes("ambient") &&
      ["temperature", "humidity", "pressure"].includes(measurement)) ||
    (sensors.includes("pax") && measurement === "pax") ||
    (sensors.includes("airQuality") && ["pm25", "pm10"].includes(measurement));

export const GraphsCard: React.FC<GraphsCardProps> = ({
  measurements,
  locationName,
}: GraphsCardProps) => {
  const { t } = useTranslation();
  return (
    <ConfigContext.Consumer>
      {(config) => (
        <>
          {measurements &&
            graphsToRender
              .filter(shouldRenderMeasure(config.sensors))
              .map((item, index) => (
                <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Card>
                    <CardContent>
                      <SingleGraphComponent
                        measureType={item}
                        measurements={measurements}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <Card>
              <CardContent>
                <Typography align="center">
                  <CSVLink
                    data={measurements.map(measurementsMapper(config.sensors))}
                    filename={`demo_${locationName}.csv`}
                  >
                    {t("download")}
                  </CSVLink>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </ConfigContext.Consumer>
  );
};
