export interface IndoorMeasurement {
  timestamp: Date;
  temperature: number;
  humidity: number;
  barometer?: number;
  co2: number;
}

export interface IndoorSensor {
  roomName: string;
  roomId: string;
  measurements: IndoorMeasurement[];
}

export interface IndoorLocation {
  latitude: number;
  longitude: number;
  locationId: number;
  locationName: string;
  sensors: IndoorSensor[];
}

export const getIndoorMeasurementRange = (
  indoorMeasurement: keyof IndoorMeasurement,
  values: number[]
): number[] | undefined => {
  if (!!!values) {
    return [0, 100];
  }
  switch (indoorMeasurement) {
    case "temperature":
      return [Math.min(...values) / 1.5, Math.max(...values) * 1.5];
    case "humidity":
      return [0, 100];
    case "barometer":
      return [Math.min(...values) / 1.01, Math.max(...values) * 1.01];
    case "co2":
      return [200, Math.max(Math.max(...values), 1000)];
    default:
      return [0, 100];
  }
};
