import { makeStyles } from "@material-ui/core/styles";

export const usePaxBoxStyles = makeStyles({
  low: {
    background: "#aaf",
    color: "#222"
  },
  medium: {
    background: "#afa",
    color: "#fff"
  },
  high: {
    background: "#eba834",
    color: "#fff"
  },
  veryHigh: {
    background: "#eb3434",
    color: "#fff"
  }
});

export const usePaxMarkerStyles = makeStyles({
  low: {
    color: "#33f"
  },
  medium: {
    color: "#3d3"
  },
  high: {
    color: "#f79d00"
  },
  veryHigh: {
    color: "#d00"
  }
});
