import * as React from "react";
import "react-vis/dist/style.css";
import {
  XYPlot,
  LineSeries,
  LineSeriesPoint,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  makeWidthFlexible,
  Crosshair,
} from "react-vis";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  getMeasurementRange,
  OutdoorMeasurement,
  MeasurementUnit,
} from "../../models";
import { HintComponent } from "../common";
import { useWidth, widthToTicks } from "../../hooks";

interface SingleGraphProps {
  measurements: OutdoorMeasurement[];
  measureType: keyof OutdoorMeasurement;
}

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

export const SingleGraphComponent: React.FC<SingleGraphProps> = ({
  measurements,
  measureType,
}: SingleGraphProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [hintValue, setHintValue] =
    React.useState<LineSeriesPoint | null>(null);
  const windowWidth = useWidth();

  const values: LineSeriesPoint[] = measurements.map<LineSeriesPoint>(
    (element) => ({
      x: new Date(element.timestamp).getTime(),
      y: element[measureType] as number,
    })
  );

  const hideHint = () => {
    setHintValue(null);
  };

  console.log(windowWidth);
  return (
    <>
      <Typography variant="h5" component="h3">
        {t(measureType)} <small>({MeasurementUnit[measureType]})</small>
      </Typography>
      <FlexibleXYPlot
        stackBy="y"
        xType="time"
        height={400}
        onMouseLeave={hideHint}
        yDomain={getMeasurementRange(
          measureType,
          values.map((el) => el.y)
        )}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          tickTotal={widthToTicks(windowWidth)}
          tickFormat={(v: Date) =>
            `${
              v.getHours() === 0
                ? `${v.getDate().toString().padStart(2, "0")}/${v
                    .getMonth()
                    .toString()
                    .padStart(2, "0")}`
                : `${v.getHours().toString().padStart(2, "0")}:${v
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}`
            }`
          }
        />
        <YAxis
          tickPadding={0}
          tickFormat={(v) => `${Number(v).toLocaleString()}`}
        />
        <LineSeries
          color={theme.palette.secondary.main}
          data={values}
          curve="natural"
          onNearestX={setHintValue}
        />
        {hintValue && (
          <Crosshair values={[hintValue]} style={{ width: "25vw" }}>
            <HintComponent
              valueName={t(measureType)}
              unit={MeasurementUnit[measureType]}
              value={hintValue}
            />
          </Crosshair>
        )}
      </FlexibleXYPlot>
    </>
  );
};
