import { makeStyles } from "@material-ui/core/styles";

export const useMainComponentStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column"
  },
  appBar: {
    flexGrow: 0,
    flexShrink: 0,
    width: "100%",
    zIndex: theme.zIndex.appBar,
    boxShadow: theme.shadows[1]
  },
  main: {
    flexGrow: 1,
    flexShrink: 1,
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    background: theme.palette.background.default
  },
  navigation: {
    flexGrow: 0,
    flexShrink: 0,
    width: "100%",
    zIndex: theme.zIndex.appBar,
    boxShadow: theme.shadows[10]
  }
}));
