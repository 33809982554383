import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import { TFunction } from "i18next";
import { getPaxTypeByPaxcount } from "../../models";

interface InfluxItemProps {
  t: TFunction;
  pax: number;
}

export const InfluxItem: React.FC<InfluxItemProps> = ({ t, pax }) => (
  <>
    <ListItem>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText>
        <strong>{t("influx")}:</strong> {t(getPaxTypeByPaxcount(pax))} ({pax}%)
      </ListItemText>
    </ListItem>
  </>
);
