import { useState, useEffect } from "react";
import { IndoorLocation } from "../models";
import { indoorMeasurementsApi } from "../api-routes";

export const useIndoorMeasurements = () => {
  const [indoorMeasurements, setIndoorMeasurements] = useState<
    IndoorLocation[]
  >([]);
  const [indoorLoading, setIndoorLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(indoorMeasurementsApi)
      .then((response) => response.json() as Promise<IndoorLocation[]>)
      .then(setIndoorMeasurements)
      .catch((error) => {
        alert("Error requesting indoor measurements");
        console.error(error);
      })
      .finally(() => {
        setIndoorLoading(false);
      });
  }, []);

  return { indoorMeasurements, indoorLoading };
};
