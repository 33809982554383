import { useState, useEffect } from "react";
import { OutdoorLocation, SearchState } from "../models";
import { filteredMeasurementsApi } from "../api-routes";

export const useFilterMeasurements = (filterState: SearchState) => {
  const [measurements, setMeasurements] = useState<OutdoorLocation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { locationId, endDate, startDate } = filterState;
  useEffect(() => {
    if (locationId === 0) {
      setLoading(false);
      setMeasurements([]);
    } else {
      setLoading(true);
      fetch(
        `${filteredMeasurementsApi}?locationId=${locationId}${
          startDate ? `&startDate=${startDate.toISOString()}` : ""
        }${endDate ? `&endDate=${endDate.toISOString()}` : ""}`
      )
        .then((response) => response.json() as Promise<OutdoorLocation[]>)
        .then((result) => {
          setMeasurements(result);
        })
        .catch((error) => {
          alert("Error requesting filtered measurements");
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [locationId, startDate, endDate]);

  return { measurements, loading };
};
