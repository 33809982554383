import { Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type BreakpointOrNull = Breakpoint | null;

export const useWidth = () => {
  const theme: Theme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

export const widthToTicks = (theWidth: "xs" | "sm" | "md" | "lg" | "xl") => {
  const ticks = {
    xs: 3,
    sm: 5,
    md: 7,
    lg: 9,
    xl: 11,
  };
  return ticks[theWidth];
};
